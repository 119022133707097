import { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';

// components
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

// styles
import styles from './SbRespStickyMenu.scss';

const RESET_PADDING = 115;
const GLOBAL_NAV_HEIGHT = 99;

export class SbRespStickyMenuComponent extends Component {
    constructor() {
        super();
        this.state = {
            isIE11: false,
            showScrollBar: false,
        };
        this.stickyMenu = createRef();
        this.stickyMenuWrapper = createRef();
        this.topVisibilityRef = createRef();
        this.scrollToTop = this.scrollToTop.bind(this);
        this.handlePageScroll = this.handlePageScroll.bind(this);
        this.resetMenuScroll = debounce(this.resetMenuScroll.bind(this), 200);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    }

    componentDidMount() {
        // determine if browser is IE 11
        // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        if (isIE11) {
            this.setState({ isIE11 });
        } else {
            window.addEventListener('scroll', this.handlePageScroll, { passive: true });
        }
    }

    componentDidUpdate(prevProps) {
        const { refetchInFlight: prevRefetchInFlight } = prevProps;
        const { refetchInFlight } = this.props;
        const { isIE11 } = this.state;

        if (prevRefetchInFlight && !refetchInFlight && !isIE11) {
            this.scrollToTop();
        }
    }

    getRect(reactElem) {
        return reactElem.current.getBoundingClientRect();
    }

    scrollToTop() {
        const { top } = this.getRect(this.stickyMenuWrapper);

        // only jump to top if menu is in sticky mode
        if (top - GLOBAL_NAV_HEIGHT <= 0) {
            window.scrollTo(window.scrollX, window.scrollY + top - RESET_PADDING);
        }
    }

    resetMenuScroll() {
        // bug with scrollTo in Edge, use scrollTop instead
        // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15534521/
        if (this.stickyMenu?.current?.scrollTop) {
            this.stickyMenu.current.scrollTop = 0;
        }
    }

    handlePageScroll() {
        if (window.scrollY === 0) {
            this.resetMenuScroll();
        }
    }

    handleVisibilityChange(isVisible) {
        this.setState({ showScrollBar: !isVisible });
    }

    render() {
        const { children } = this.props;
        const { isIE11, showScrollBar } = this.state;

        const stickyMenuClassNames = classnames(styles.menu, {
            [styles.fullHeight]: isIE11,
            [styles.scrollBar]: showScrollBar,
        });

        return (
            <div ref={this.stickyMenuWrapper} className={styles.wrapper}>
                <Fragment>
                    <div ref={this.topVisibilityRef} className={styles.topVisibilityOffset} />
                    <VisibilityTracker
                        elementRef={this.topVisibilityRef}
                        onVisibilityChange={({ isVisible }) =>
                            this.handleVisibilityChange(isVisible)
                        }
                        watchAfterFirstVisible
                    />
                    <div ref={this.stickyMenu} className={stickyMenuClassNames}>
                        {children}
                    </div>
                </Fragment>
            </div>
        );
    }
}

SbRespStickyMenuComponent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    refetchInFlight: PropTypes.bool,
};

const mapStateToProps = state => {
    const { filters } = state;

    return {
        refetchInFlight: filters.refetchInFlight,
    };
};

export const SbRespStickyMenu = connect(mapStateToProps)(SbRespStickyMenuComponent);
