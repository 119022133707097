import { graphql, readInlineData } from 'react-relay';
import { trackEcommerce, trackingConstants, eventNameConstants } from 'dibs-tracking';
import {
    getIsFreeShipping,
    UserRegions,
} from 'dibs-search-product-tile/exports/shippingCostHelpers';
import { Maybe } from 'dibs-ts-utils/exports/Maybe';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

import {
    sharedRelatedItemsWrapperTracking_item$key,
    sharedRelatedItemsWrapperTracking_item$data,
} from './__generated__/sharedRelatedItemsWrapperTracking_item.graphql';

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

const itemsFragment = graphql`
    fragment sharedRelatedItemsWrapperTracking_item on Item @inline {
        serviceId
        contemporaryTrackingString
        title
        browseUrl
        categoryCode
        seller {
            serviceId
        }
        ...shippingCostHelpers_item
    }
`;

export type Item = Maybe<sharedRelatedItemsWrapperTracking_item$key>;

type ActionField = { list: string; itemId: Maybe<string> };

type trackItemsProp = {
    items: (sharedRelatedItemsWrapperTracking_item$data | null | undefined)[];
    type: typeof ECOM_PRODUCT_CLICK | typeof ECOM_PRODUCT_IMPRESSION;
    eventName: typeof EVENT_SELECT_ITEM | typeof EVENT_VIEW_ITEM_LIST;
    startIndex: number;
    list: string;
    actionField?: ActionField;
    userRegions: UserRegions;
};

const trackItems = ({
    items,
    type,
    eventName,
    startIndex,
    list,
    actionField,
    userRegions,
}: trackItemsProp): void => {
    if (items?.length) {
        trackEcommerce({
            type,
            eventName,
            actionField,
            products: items.filter(filterFalsy).map((item, index) => ({
                brand: item.seller?.serviceId,
                category: item.browseUrl || item?.categoryCode,
                categoryCode: item.categoryCode,
                dimension76: 'list price',
                dimension83: item.contemporaryTrackingString,
                dimension106: getIsFreeShipping({ item, userRegions }) ? 'Y' : 'N',
                id: item.serviceId,
                list,
                name: item.title,
                position: index + startIndex + 1, // Tracking needs non zero index position.
            })),
        });
    }
};

type Props = {
    items: Item[];
    startIndex: number;
    list: string;
    userRegions: UserRegions;
};

export const trackItemsImpression = ({ items: itemsRef, ...props }: Props): void => {
    const items = itemsRef?.map(item => item && readInlineData(itemsFragment, item));

    trackItems({
        items,
        type: ECOM_PRODUCT_IMPRESSION,
        eventName: EVENT_VIEW_ITEM_LIST,
        ...props,
    });
};

export const trackItemClick = ({
    items: itemsRef,
    startIndex = 0,
    list,
    userRegions,
}: Props): void => {
    const items = itemsRef?.map(item => item && readInlineData(itemsFragment, item));
    const actionField: ActionField = { list, itemId: items?.[0]?.serviceId };

    trackItems({
        items,
        type: ECOM_PRODUCT_CLICK,
        eventName: EVENT_SELECT_ITEM,
        startIndex,
        list,
        actionField,
        userRegions,
    });
};
