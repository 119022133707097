import SV from 'server-vars';

export function handleSbUserCountryCode({
    userCountryCode,
    fetchSb,
}: {
    userCountryCode?: string;
    fetchSb?: boolean;
}): string {
    const serverUserCountryCode = SV.get('relay.variables.userCountryCode') || '';

    if (userCountryCode) {
        /**
         * There's a chance that the server side `userCountryCode` and the client side one will not match.
         *
         * One case that we know of is when you're on your devbox, since env-boot doesn't do any country detection.
         *
         * In that case server side and client side relay variables will be different, we won't be able to reuse the
         * server side data for `itemSearch`, it will be `null` and the page will crash.
         *
         * To prevent that, for the initial refetch query, we will always use the server side value for `userCountryCode`.
         */
        return fetchSb ? userCountryCode : serverUserCountryCode;
    }

    return serverUserCountryCode;
}
