/**
 * @generated SignedSource<<0c6c9dea68c9a99ab495fd61dfe26b47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSortItems_user$data = {
  readonly hasBasicInternalPermission: boolean | null;
  readonly hasMerchandizingPermission: boolean | null;
  readonly " $fragmentType": "SbRespSortItems_user";
};
export type SbRespSortItems_user$key = {
  readonly " $data"?: SbRespSortItems_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSortItems_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSortItems_user",
  "selections": [
    {
      "alias": "hasBasicInternalPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "BASIC_INTERNAL"
        }
      ],
      "kind": "ScalarField",
      "name": "hasPermission",
      "storageKey": "hasPermission(name:\"BASIC_INTERNAL\")"
    },
    {
      "alias": "hasMerchandizingPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "BUYER_FE_MERCH_TOOL"
        }
      ],
      "kind": "ScalarField",
      "name": "hasPermission",
      "storageKey": "hasPermission(name:\"BUYER_FE_MERCH_TOOL\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ee64b4084aceaf26d07ee23b474ff6a3";

export default node;
