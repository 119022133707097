import { FC, ReactNode } from 'react';
import style from './fullPageWidth.scss';
import classnames from 'classnames';

type Props = {
    className?: string;
    innerClassName?: string;
    hasBackground?: boolean;
    hasPadding?: boolean;
    children: ReactNode;
    dataTn?: string;
};

export const FullPageWidth: FC<Props> = ({
    className,
    innerClassName,
    hasBackground = false,
    hasPadding = false,
    dataTn,
    children,
    ...otherProps
}) => {
    const classNames = classnames(style.container, className, {
        [style.background]: hasBackground,
    });
    const innerClassNames = classnames(innerClassName, { [style.padding]: hasPadding });

    return (
        <div className={classNames} {...otherProps} data-tn={dataTn}>
            <div className={innerClassNames}>{children}</div>
        </div>
    );
};
