/**
 * @generated SignedSource<<6fae17663f488c2c7f1a55c20652b3fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuDisplayPrefsSelect_filters$data = ReadonlyArray<{
  readonly name: string | null;
  readonly values: ReadonlyArray<{
    readonly displayName: string | null;
    readonly linkReference: string | null;
    readonly linkable: boolean | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentType": "SbSharedRefineMenuDisplayPrefsSelect_filters";
}>;
export type SbSharedRefineMenuDisplayPrefsSelect_filters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefineMenuDisplayPrefsSelect_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefineMenuDisplayPrefsSelect_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "a264da0126fcc9a9f2bef2e6376700cb";

export default node;
