/**
 * @generated SignedSource<<b44d274aafd750df4954f2d0b72c57a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedMobileRelatedItem_user$data = {
  readonly canShowSellerName: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesItemProvider_user">;
  readonly " $fragmentType": "SharedMobileRelatedItem_user";
};
export type SharedMobileRelatedItem_user$key = {
  readonly " $data"?: SharedMobileRelatedItem_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedMobileRelatedItem_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedMobileRelatedItem_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canShowSellerName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesItemProvider_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "67bf2c056ccdd416a8565e10a91a66ab";

export default node;
