import { Component } from 'react';
import { graphql, createFragmentContainer } from 'react-relay/legacy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { IntlContext, defineMessages } from 'dibs-react-intl';

import { toggleFilterDisplay, updateUriRef } from '../../../actions/filterActions';
import { createNewFilterUriRef } from '../../../utils/uriUtils';
import { pageTypeConstants as pageTypes } from '../../../constants/pageTypeConstants';
import { QP_SEARCH_QUERY } from '../../../constants/queryParamConstants';
import { SbSharedRefineMenuSearchInput } from '../SbSharedRefineMenuSearchInput/SbSharedRefineMenuSearchInput';
import { FILTER_REFINE_SEARCH } from '../sbSharedRefineMenuConstants';

import styles from './SbSharedRefineMenuSearchWithin.scss';

const messages = defineMessages({
    placeholder: {
        id: 'sb.SbSharedRefineMenu.searchWithin.placeholder',
        defaultMessage: `Search within {totalResultsCount, plural, one {# result} other {# results}}`,
    },
    placeholderNoCount: {
        id: 'sb.SbSharedRefineMenu.searchWithin.placeholderNoCount',
        defaultMessage: `Search within results`,
    },
});

export class SbSharedRefineMenuSearchWithinComponent extends Component {
    constructor(props) {
        super(props);

        const appliedSearchFilter = props.itemSearch.appliedFilters?.find(
            filter => filter?.name === FILTER_REFINE_SEARCH
        );
        const inputValue = appliedSearchFilter?.values?.[0]?.urlLabel || '';

        this.state = {
            inputValue,
        };

        this.onInput = this.onInput.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.filtersShown && !newProps.filtersShown) {
            this.handleSearchSubmit();
        }
    }

    onInput(e) {
        this.setState({
            inputValue: e.target.value,
        });
    }

    onSubmit(e) {
        e.preventDefault();
        this.handleSearchSubmit(e);
    }

    handleSearchSubmit(e) {
        if (this.state.inputValue === '') {
            return;
        }
        // apply filters with new value
        this.props.updateFilters(this.state.inputValue, this.props.generatedUriRef, e);
        this.setState({ inputValue: '' });
        this.props.onSubmit();
    }

    render() {
        const { horizontalSpacing, itemSearch, size, hideResultsCount } = this.props;
        const { totalResults, pageType } = itemSearch;
        const { inputValue } = this.state;
        const placeholder = this.context.formatMessage(
            hideResultsCount ? messages.placeholderNoCount : messages.placeholder,
            {
                totalResultsCount: totalResults ?? 0,
            }
        );

        return pageType !== pageTypes.SEARCH ? (
            <SbSharedRefineMenuSearchInput
                autoComplete="on"
                className={styles.container}
                horizontalSpacing={horizontalSpacing}
                onInput={this.onInput}
                onSubmit={this.onSubmit}
                placeholder={placeholder}
                size={size}
                value={inputValue}
            />
        ) : null;
    }
}

SbSharedRefineMenuSearchWithinComponent.contextType = IntlContext;

SbSharedRefineMenuSearchWithinComponent.propTypes = {
    filtersShown: PropTypes.bool.isRequired,
    horizontalSpacing: PropTypes.oneOf(['small', 'medium']),
    onSubmit: PropTypes.func.isRequired,
    itemSearch: PropTypes.object.isRequired,
    size: PropTypes.oneOf(['medium', 'large']),
    updateFilters: PropTypes.func.isRequired,
    hideResultsCount: PropTypes.bool,
    generatedUriRef: PropTypes.string,
};

SbSharedRefineMenuSearchWithinComponent.defaultProps = {
    appliedSearchValue: '',
    horizontalSpacing: 'medium',
    onSubmit: () => {},
    size: 'medium',
    hideResultsCount: false,
};

const mapStateToProps = ({ filters }) => ({
    filtersShown: filters.filtersShown,
    generatedUriRef: filters.generatedUriRef,
});

function mapDispatchToProps(dispatch) {
    return {
        updateFilters(value, generatedUriRef, event) {
            const filterName = FILTER_REFINE_SEARCH;
            const filterValue = {
                urlLabel: value,
            };
            const uriRef = createNewFilterUriRef(generatedUriRef, QP_SEARCH_QUERY, value);

            dispatch(updateUriRef({ filterName, filterValue, uriRef, event }));
            dispatch(toggleFilterDisplay(false));
        },
    };
}

export const SbSharedRefineMenuSearchWithin = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(SbSharedRefineMenuSearchWithinComponent),
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuSearchWithin_itemSearch on ItemSearchQueryConnection {
                pageType
                totalResults
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
    }
);
