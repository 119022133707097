/**
 * @generated SignedSource<<92ad3b60a6b7cde33f91508c2e406dfe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSortItems_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly sort: {
    readonly currentOption: {
      readonly urlLabel: string | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SbRespSortItems_itemSearch";
};
export type SbRespSortItems_itemSearch$key = {
  readonly " $data"?: SbRespSortItems_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSortItems_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSortItems_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SortType",
      "kind": "LinkedField",
      "name": "sort",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseSortAvailableOptions",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkReference",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseSortCurrentOption",
          "kind": "LinkedField",
          "name": "currentOption",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0b83324604224185a34f072e8f0f03cf";

export default node;
