/**
 * @generated SignedSource<<ba2b439b6f91f755d9ccfc6969cc9e04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilters_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterArtSize_user" | "SbMobileRefineMenuFilterDimensions_user" | "SbMobileRefineMenuFilterPrice_user" | "SbRespRefineMenuFilterArtSize_user" | "SbRespRefineMenuFilterDimensions_user" | "SbRespRefineMenuFilterPrice_user" | "SbSharedRefineMenuFilterDeliveryOptions_user" | "useMeasurementUnit_user">;
  readonly " $fragmentType": "SbSharedRefineMenuFilters_user";
};
export type SbSharedRefineMenuFilters_user$key = {
  readonly " $data"?: SbSharedRefineMenuFilters_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilters_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterPrice_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterDimensions_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterPrice_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterDimensions_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterArtSize_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterArtSize_user"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_user",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPreferences",
          "kind": "LinkedField",
          "name": "preferences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "measurementUnit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterDeliveryOptions_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e5a576353862ae9ea44bf9d183266786";

export default node;
