import { useId, ReactNode, FC, MouseEventHandler } from 'react';
import classnames from 'classnames';
import { useIntl, defineMessages } from 'dibs-react-intl';

import Close from 'dibs-icons/exports/legacy/Close';
import { Link } from '../Link';

// styles
import styles from './main.scss';

const spacingClasses = {
    none: 'noneSpacing',
    xSmall: 'xSmallSpacing',
} as const;

const messages = defineMessages({
    deleteLabel: { id: 'SearchTag.deleteLabel', defaultMessage: 'Delete' },
});

type SearchTagProps = {
    isActive?: boolean;
    disabled?: boolean;
    onDelete?: MouseEventHandler;
    dataTn?: string;
    children: ReactNode;
    hasDeleteButton?: boolean;
    tagSpacing?: 'xSmall' | 'none';
};
export const SearchTag: FC<SearchTagProps> = props => {
    const {
        isActive = true,
        disabled = false,
        onDelete = () => {},
        dataTn,
        children,
        hasDeleteButton = true,
        tagSpacing = 'none',
    } = props;

    const htmlId = useId();
    const intl = useIntl();

    const wrapperClassName = classnames(
        styles.searchTagWrapper,
        tagSpacing && styles[spacingClasses[tagSpacing]]
    );

    const tagClassName = classnames(styles.searchTag, {
        [styles.searchTagEnabled]: !disabled,
        [styles.searchTagActive]: isActive && !disabled,
    });
    const deleteClassName = classnames(styles.deleteButton, {
        [styles.deleteButtonEnabled]: !disabled,
    });
    const labelClassNames = classnames(styles.searchTagLabel, {
        [styles.noDeleteButton]: !hasDeleteButton,
    });
    const deleteClick = disabled ? () => {} : onDelete;

    return (
        <div className={wrapperClassName}>
            <div className={tagClassName} data-tn={dataTn}>
                <div id={htmlId} className={labelClassNames}>
                    {children}
                </div>
                {hasDeleteButton && (
                    <Link
                        ariaDescribedBy={htmlId}
                        ariaLabel={intl.formatMessage(messages.deleteLabel)}
                        className={deleteClassName}
                        onClick={deleteClick}
                    >
                        <Close className={styles.closeIcon} />
                    </Link>
                )}
            </div>
        </div>
    );
};
