const encode: (str: string) => string =
    typeof btoa === 'function' ? btoa : str => Buffer.from(str).toString('base64');

export const encode64 = (str: string = ''): string => {
    try {
        return encode(str);
    } catch (e) {
        return '';
    }
};
