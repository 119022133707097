/**
 * @generated SignedSource<<1d323b559acc79d830402746a73f27aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRecentSales_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_viewer">;
  readonly " $fragmentType": "SharedRecentSales_viewer";
};
export type SharedRecentSales_viewer$key = {
  readonly " $data"?: SharedRecentSales_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRecentSales_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRecentSales_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "934876d1d1da1979e6eb92f7659ea902";

export default node;
