import serverVars from 'server-vars';
import { UserRelayVars } from './SharedUserAndRelayVarLoader';
import { CUSTOMER_TYPE_TRADE } from '../constants/userConstants';

/**
 * Need to refetch whole page items/data for trade or EU(VAT) users, the price depends
 * on the `isTrade` and/or `priceBookName` variable being set.
 *
 * Only do this after login, hence the server vars check, subsequent visits will depend on
 * the CDN query params.
 */
export function handlePageRefetchVariableOnUserChange({
    isTrade: userIsTrade,
    priceBookName,
}: UserRelayVars): boolean {
    const userTypeOnServer = serverVars.get('userType');
    const priceBookNameOnServer = serverVars.get('relay.variables.priceBookName');

    if (userIsTrade && userTypeOnServer !== CUSTOMER_TYPE_TRADE) {
        return true;
    } else if (priceBookName !== priceBookNameOnServer) {
        return true;
    }
    return false;
}
